<template>
  <div class="wa__manager__item mb-2">
    <v-row>
      <v-col cols="4" class="">
        <div class="wa__manager__name">
          <div class="wa__manager__name--image">
            <img :src="managerItem.thumbnail" alt="model image" />
          </div>
          <div>
            <Name
              class="ml-4"
              :title="managerItem.name"
              :sub-title="managerItem.email"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="4" class="wa__manager__history">
        <div class="wa__manager__history--join d-flex align-center h-100">
          <block-button
            height="13"
            :text="'Joined From ' + managerItem.joinded_at"
            class="btn-join"
          ></block-button>
        </div>
      </v-col>
      <v-col cols="4" class="d-flex justify-content-end align-items-center">
        <div class="d-flex justify-content-end align-items-center pr-2">
          <circle-button
            icon="mdi-account-convert"
            color="cyan"
            bg-color="bgCyan"
            @click.native="
              $_openModal('changeStatus', { manager: managerItem })
            "
          ></circle-button>
          <circle-button
            icon="WMi-pencil"
            color="black"
            @click.native="$_openModal('managerModal', { model: managerItem })"
          ></circle-button>
          <circle-button icon="WMi-align-left" color="black"></circle-button>
          <circle-button
            icon="WMi-trash"
            color="red"
            bg-color="bgLightPink"
            @click.native="deleteMessage"
          ></circle-button>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Name from "../Global/Section/Name.vue";
import toast from "@/utils/toast";
import { mapActions } from "vuex";
export default {
  components: { Name },
  props: {
    managerItem: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("manager", ["deleteManager"]),
    deleteMessage() {
      toast.question(this.$_trans("toast.del_manager_item_msg"), this.$_trans("toast.del_manager_item_title"), async () => {
        await this.deleteManager(this.managerItem.id);
      });
    },
  },
};
</script>
<style scoped>
.wa__manager__item {
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}
/* manager item */
.wa__manager__name {
  display: flex;
}
.wa__manager__name--image img {
  height: 55px;
  border-radius: 4px;
}
.wa__manager__history--join {
  padding-left: 64px;
}

/* button */
.btn-join {
  font-family: "Montserrat-regular";
  color: var(--color-gray);
  font-size: 8px;
  letter-spacing: 0px !important;
  border-radius: 1px !important;
  background-color: var(--color-bg-gray) !important;
}
</style>
