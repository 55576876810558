<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3 pl-1">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('managers')"
              :sub-title="$_t('subTitle')"
              :login="login"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="justify-content-center pb-4 wa__manage">
          <v-col cols="12" md="2">
            <ManagerFilter />
          </v-col>
          <v-col cols="12" md="10">
            <ManagerItems />
          </v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer text="All Right Reserved"></site-footer>
      </div>
    </footer>
    <div class="text-center">
      <ManagerModal v-if="isModal('modal_managerModal')" />
      <ModalStatus v-if="isModal('modal_changeStatus')" />
    </div>
  </div>
</template>
<script>
import { mapGetters,mapMutations } from "vuex";
import ManagerFilter from "../components/Manager/Filter.vue";
import ManagerItems from "../components/Manager/Items.vue";
import ManagerModal from "../components/Manager/ManagerModal.vue";
import ModalStatus from "../components/Manager/ModalStatus.vue";
export default {
  components: {
    ManagerFilter,
    ManagerItems,
    ManagerModal,
    ModalStatus,
  },
  data() {
    return {
      login: true,
    };
  },
  computed: {
    ...mapGetters("modal", ["isModal"]),
  },
  methods: {
    ...mapMutations('manager', ['DESTROY_HISTORY']),
  },
  destroyed(){
    this.DESTROY_HISTORY()
  }
};
</script>