<template>
  <v-form @submit.prevent="submit" class="mb-3 h-100">
    <list :title="$_t('filters')" :filter="true" class="h-100">
      <hr class="mt-0" />
      <v-row>
        <v-col cols="12" class="pt-4">
          <v-text-field
            :label="$_t('managerName')"
            color="SonicSilver"
            class="no-error-msg"
            v-model="filter.name.val"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-2">
          <date-input
            :label="$_t('dateAfter')"
            color="SonicSilver"
            v-model="filter.created_at.val1"
            class="no-error-msg"
            outlined
            dense
          ></date-input>
        </v-col>
        <v-col cols="12" class="pt-1">
          <date-input
            :label="$_t('dateBefore')"
            color="SonicSilver"
            class="no-error-msg"
            v-model="filter.created_at.val2"
            outlined
            dense
          ></date-input>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="4" class="pr-0">
          <block-button
            height="30"
            class="w-100 cancel__button br__mode btn__size__14"
            :text="$_t('clear')"
            color="black"
            @click.native="clearFilter"
          ></block-button>
        </v-col>
        <v-col cols="8 pl-1">
          <block-button
            height="30"
            class="w-100 site__button width-auto add__mode btn__size__18"
            :text="$_t('execute')"
            type="submit"
          ></block-button>
        </v-col>
      </v-row>
    </list>
  </v-form>
</template>
<script>
import { mapActions, mapMutations,mapGetters } from "vuex";
import { cloneDeep } from "lodash";
const defaultFilter = {
  created_at: {
    type: "between",
    val1: null,
    val2: null,
  },
  name: {
    type: "like",
    val: null,
  },
};
export default {
  data() {
    return {
      filter: {},
    };
  },
  computed: {
    ...mapGetters('manager', ['getFiltersManager']),
  },
  methods: {
    ...mapMutations("manager", ["SET_FILTER_MANAGER","SET_IS_FILTERED_MANAGER"]),
    ...mapActions("manager", ["loadManagers"]),
    submit() {
      this.SET_IS_FILTERED_MANAGER(true);
      this.SET_FILTER_MANAGER(this.filter);
      this.loadManagers();
    },
    clearFilter() {
      this.SET_IS_FILTERED_MANAGER(false);
      this.filter = cloneDeep(defaultFilter);
      this.SET_FILTER_MANAGER(cloneDeep(this.filter));
      this.loadManagers();
    },
    loadPastFilter(){
      this.filter = cloneDeep({...defaultFilter , ...this.getFiltersManager})
    }
  },
  created(){
    this.loadPastFilter();
  }
};
</script>
