<template>
  <list :title="$_t('managers')" class="wa__large pb-6">
    <template #header-btn>
      <!-- <block-button
        height="37"
        class="px-10 btn__size__18 site__button add__mode width-200 mt-n1"
        text="ADD"
        icon="WMi-plus-linear"
        @click.native="$_openModal('managerModal')"
      ></block-button> -->
    </template>
    <hr class="mt-0" />
    <template>
      <v-row class="px-7 pb-1 d-none d-md-flex">
        <v-col cols="4" class="wa__client__title--title pl-15"
          ><span>{{ $_t("cellphoneOrName") }}</span></v-col
        >
        <v-col cols="4" class="wa__client__title--title pl-16"
          ><span class="pl-2">{{ $_t("requests") }}</span></v-col
        >
        <v-col cols="4" class="wa__client__title--title text-right pr-10"
          ><span>{{ $_t("controls") }}</span></v-col
        >
      </v-row>
      <data-iterator
        :items="getManagers"
        :is-filtered="isFilteredManager"
        @pagination="changePagination"
        :pagination="getPaginationManager"
        :loading="getManagerLoading"
        class="row mt-0 px-3"
      >
        <template v-slot:loading>
          <loading />
        </template>
        <template #no-items>
          <!-- fix component -->
          <!-- <no-items :src="$_staticPath('images/global/animated/product.gif')" route-name="AddProduct" textFa=" هنوز هیچ محصولی ثبت نکرده اید! " textEn=" No Products Yet! " themeColor="product" btnText="ثبت اولین  محصول" btnIcon="plus" /> -->
          <no-items textEn=" No Products Yet! " />
        </template>
        <template #no-results>
          <!-- fix component -->
          <!-- <no-items :src="$_staticPath('images/global/animated/no-result.gif')" textFa=" متاسفانه محصولی مطابق با فیلتر های شما پیدا نکردیم! " textEn=" Sorry, No Product! " themeColor="product" btnText=" حذف فیلتر ها " /> -->
          <no-items />
        </template>

        <template v-slot:items="{ item }">
          <ManagerItem :key="item.id" :managerItem="item" />
        </template>
      </data-iterator>
    </template>
  </list>
</template>

<script>
import ManagerItem from "./Item.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    ManagerItem,
  },
  computed: {
    ...mapGetters("manager", [
      "getManagers",
      "getPaginationManager",
      "getManagerLoading",
      "isFilteredManager",
    ]),
  },
  methods: {
    ...mapActions("manager", ["loadManagers"]),
    ...mapMutations("manager", ["SET_PAGINATION"]),
    changePagination(page) {
      if (this.getPaginationManager.page !== page) {
        this.SET_PAGINATION(page);
        this.loadManagers();
      }
    },
  },
  created() {
    this.loadManagers();
  },
};
</script>

<style scoped>
.wa__client__title--title {
  font-family: "Montserrat";
  font-size: 0.6rem;
  color: var(--color-light-gray);
}
</style>
