<template>
  <basic-modal @open="openModal" width="700">
    <template #header>
      <divider title="new manager" sub-title="LET'S EXPAND" size="small"></divider>
    </template>
    <template #default>
      <div class="mt-4 px-4 pb-2">
        <v-row>
          <v-col cols="12">
            <v-form @submit.prevent="addManager">
              <div class="px-4 pb-2">
                <image-cropper
                  :url.sync="form.thumbnail"
                  v-model="fileForm"
                  label="manager image"
                />
                <div class="wa__modal__input">
                  <v-text-field
                    label="first name"
                    v-model="form.first_name"
                    color="SonicSilver"
                    dense
                    outlined
                  ></v-text-field>
                </div>
                <div class="wa__modal__input">
                  <v-text-field
                    label="last name"
                    v-model="form.last_name"
                    color="SonicSilver"
                    dense
                    outlined
                  ></v-text-field>
                </div>
                <div class="wa__modal__input">
                  <v-text-field
                    label="email"
                    color="SonicSilver"
                    autocomplete="email"
                    v-model="form.email"
                    dense
                    outlined
                  ></v-text-field>
                </div>
                <div class="wa__modal__input">
                  <v-text-field
                    autocomplete="new-password"
                    label="password"
                    color="SonicSilver"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    :append-icon="showPassword ? 'WMi-eye-off' : 'WMi-eye'"
                    v-model="form.password"
                    dense
                    outlined
                  ></v-text-field>
                </div>
                <div class="wa__modal__input">
                  <v-text-field
                    label="password confirmation"
                    autocomplete="new-password"
                    :type="showPassConfig ? 'text' : 'password'"
                    @click:append="showPassConfig = !showPassConfig"
                    :append-icon="showPassConfig ? 'WMi-eye-off' : 'WMi-eye'"
                    color="SonicSilver"
                    v-model="form.password_confirmation"
                    dense
                    outlined
                  ></v-text-field>
                </div>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-5 py-2 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel px-0 btn__size__18"
          text="cancel"
          icon="WMi-cancel-linear"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="28"
          class="btn__modal--assign ml-5 site__button width-auto btn__size__16"
          :text="form.id ? 'update' : 'add'"
          icon="WMi-plus-linear"
          @click.native="addManager"
          :loading="loadingRequest"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import { mapActions } from "vuex";
import { makeid } from "@/utils/math";
import FileRepository from "../../abstraction/repository/FileRepository";
import ImageCropper from "@/components/Global/Input/ImageCropper";
const RANDOM_TOKEN = makeid(50);
export default {
  name: "modal_managerModal",
  components: {
    ImageCropper,
  },
  data() {
    return {
      form: {
        thumbnail: "https://via.placeholder.com/600x600",
        file_batch_id: RANDOM_TOKEN,
      },
      fileForm: {
        batch_id: RANDOM_TOKEN,
        model_name: "user",
        collection_name: "main_image",
      },
      showPassword: false,
      showPassConfig: false,
      loadingRequest: false,
    };
  },
  methods: {
    openModal({ model }) {
      if (model) {
        this.form = { ...model };
      }
    },
    ...mapActions("manager", ["storeManager", "updateManager"]),
    async addManager() {
      try {
        this.loadingRequest = true;
        if (this.fileForm.file) {
          let repository = new FileRepository();
          await repository.store(this.fileForm);
        }

        if (this.form.id) {
          await this.updateManager(this.form);
        } else {
          await this.storeManager(this.form);
        }
        this.loadingRequest = false;
        this.$_closeModal();
      } catch (e) {
        return e;
      }
    },
  },
};
</script>
<style scoped>
.v-card__text.wa__modal-content {
  padding-bottom: 10px !important;
}
.v-input.v-textarea.theme--light.v-text-field.v-text-field--is-booted {
  padding: 30px 0 0 10px !important;
}
.wa__modal__input {
  height: 60px;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
/* image */

.wa__modal__image {
  width: 90%;
  height: 170px;
  margin: 0 auto;
  border: 1px dashed var(--color-gray);
  border-radius: 5px;
  margin-top: 21px;
}
.wa__modal__image img {
  height: 100%;
  width: 100%;
}
</style>
