<template>
  <basic-modal
    @open="openModal"
    width="700"
    transition="slide-x-transition"
    not-footer="true"
  >
    <template #header>
      <divider
        title="change status"
        sub-title="THE REQUEsST STATUS"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="px-5 mt-3 pb-7">
        <status-request :items="getStatuses" v-model="role" />
      </div>
    </template>
  </basic-modal>
</template>
<script>
import StatusRequest from "../Global/Section/StatusRequest.vue";
import { mapActions } from "vuex";
export default {
  name: "modal_changeStatus",
  components: {
    StatusRequest,
  },
  methods: {
    ...mapActions("manager", ["changeRoll"]),
    openModal({ manager }) {
      this.pastStatusId = manager.type === "manager" ? 1 : 2;
      this.role = this.pastStatusId ;
      this.form = { ...manager };
    },
  },
  data: () => ({
    form: {},
    role: null,
    pastStatusId: null,
    getStatuses: [
      {
        id: 1,
        name: "manager",
        description: "manager",
        color: "cyan",
      },
    ],
  }),
  mounted() {
    if(this.role!==1){
      this.getStatuses.push({
        id: 2,
        name: "client",
        description: "client",
        color: "red",
      })
    }
  },
  watch: {
    role: {
      async handler(newRole) {
        try {
          if (newRole !== this.pastStatusId) {
            this.form.type = newRole === 1 ? "manager" : "client"
            await this.changeRoll(this.form)
            this.$_closeModal();
          }
        } catch (e) {
          return e;
        }
      },
      deep: true,
    },
  },
};
</script>
